import HeroAppDownload from '../HeroAppDownload';
import Page from '../Page';

const description = 'Download the GasBuddy App for free and start saving immediately on your next visit to the gas station. Fuel better using our app for savings, rewards & more!';
const title = 'GasBuddy™ App - Find Cheaper Gas Prices & Save Up to 30¢/Gallon';

export default function AppPage() {
  return (
    <Page
      name="App_Page"
      compact
      title={title}
      description={description}
    >
      <HeroAppDownload useH1Header />
    </Page>
  );
}
