import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { Container, Header, Image, Panel, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import AppBadges from '../AppBadges';
import styles from './HeroAppDownload.module.css';

const cx = classnames.bind(styles);

export const IS_MOBILE_DEVICE = gql`
  query isMobileDevice {
    config {
      isMobile
    }
  }
`;

export default function HeroAppDownload({ useH1Header }) {
  const { data } = useQuery(IS_MOBILE_DEVICE);
  const isOnMobileDevice = data?.config?.isMobile;

  let linkContent;
  if (isOnMobileDevice) {
    linkContent = (
      <AppBadges analyticsScreenName="App_Page" fluid showReviewSections />
    );
  } else {
    linkContent = (
      <div className={cx('qrCodeContainer')}>
        <Image
          alt="Download App QR code"
          className={cx('qrCode')}
          src="https://static.gasbuddy.com/web/download-app-qr-code.svg"
        />
      </div>
    );
  }

  const actionText = isOnMobileDevice
    ? ' Download'
    : ' Use the camera on your phone to scan the QR code below to download';

  return (
    <div className={cx('heroImageBackground')}>
      <Container>
        <Panel className={cx('appDownloadContent')} color="white" rounded={false}>
          <Header as={useH1Header ? 'h1' : 'h2'} className={cx('header')} color="midnight">
            GasBuddy App - Find Local Gas Prices, Track Rewards & More!
          </Header>
          <Text as="p" className={cx('subHeader')} color="midnight">
            For over 20 years, GasBuddy has saved users over $3.1 billion.
            {actionText} our easy-to-use app to start saving at the pump, unlock exclusive deals and rewards, and
            complete fun challenges for the chance to win free gas today.
          </Text>
          <br />
          {linkContent}
        </Panel>
      </Container>
    </div>
  );
}

HeroAppDownload.propTypes = {
  useH1Header: PropTypes.bool,
};

HeroAppDownload.defaultProps = {
  useH1Header: false,
};
